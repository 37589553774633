/**
 * /* eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/**
 * /* eslint-disable @typescript-eslint/restrict-template-expressions
 *
 * @format
 */

/**
 * /* eslint-disable @typescript-eslint/restrict-template-expressions
 *
 * @format
 */

/**
 * /* eslint react/prop-types: 1, @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/restrict-template-expressions: 1, array-callback-return: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/prop-types: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/restrict-template-expressions: 1
 *
 * @format
 */
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Collapse,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import {useEffect, useState} from 'react';
import useUser from '../../../lib/useUser';
import LinkButton from '../../components/LinkButton';
import AccountAvatar from '../../icons/AccountAvatar/Index';
import ChevronDown24 from '../../icons/ChevronDown24';
import ChevronUp24 from '../../icons/ChevronUp24';
import Close from '../../icons/Close';
import Hamburger from '../../icons/Hamburger';
import LogoASSC from '../../icons/logos/LogoASSC';
import {fonts} from '../../styles/js/fonts';
import MainMenuProps from './MainMenuProps';

const joinLink = {
	url: '/join-the-assc',
	title: 'Join',
	target: '_self',
};
const logInLink = {
	url: '/members/login',
	title: 'Log in',
	target: '_self',
};
const logOutApiButton = {
	url: '/api/member/logout',
	title: 'Log out',
};

function MainMenu({logoLink, cta, menuPages, menuTopLevel}: MainMenuProps) {
	const [activeSubscription, setActiveSubscription] = useState([]);
	const {isOpen: menuIsOpen, onToggle: menuOnToggle} = useDisclosure();
	const {user} = useUser();

	useEffect(() => {
		const fetchSubscriptions = async () => {
			if (!user?.id) {
				return;
			}

			try {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const {data} = await axios.post('/api/stripe/findActiveSubscription', {
					cmsUserId: user?.id,
				});
				setActiveSubscription(data?.activeSubscriptions || []);
			} catch (e: unknown) {
				console.error('Finding activeSubscriptions failed', e);
			}
		};

		void fetchSubscriptions();

		return () => {
			setActiveSubscription([]);
		};
	}, [user?.id]);

	return (
		<Box position='sticky' top='0' zIndex={'2'} h={{md: '7.5rem'}}>
			<Box
				id='mainNav'
				as='menu'
				position='relative'
				my='0'
				bg={'#fff'}
				px={{
					base: '16px',
					md: '3.5rem',
				}}
				py={{
					base: '16px',
					md: '2rem',
				}}
				boxShadow='0 2px 4px 0 #0000002b'
				fontFamily={fonts.base}>
				<Flex justifyContent={'space-between'}>
					{/* Logo: */}
					<HStack>
						<Box as='a' href={'/'}>
							<LogoASSC width='137' height='44' />
						</Box>

						<Box
							display={{
								base: 'none',
								md: 'flex',
							}}
							h='63px'
						/>
					</HStack>
					{/* Top Nav: */}

					<HStack>
						{/* Menu items: */}
						{menuTopLevel && menuTopLevel.length > 0 ? (
							<MenuTopLevel
								menuTopLevel={menuTopLevel}
								isLoggedIn={user?.isLoggedIn}
								activeSubscription={activeSubscription}
							/>
						) : null}
						{/* Join and login: */}
						<Box display={{base: 'none', xl: 'flex'}}>
							<MemberLinks isLoggedIn={user?.isLoggedIn} />
						</Box>
						{/* Hamburger icon: */}
						<IconButton
							colorScheme=''
							onClick={menuOnToggle}
							aria-label='Toggle menu'
							variant='unstyled'
							w={'auto'}
							display={{xl: 'none'}}
							icon={
								<>
									{menuIsOpen ? (
										<Close color='#3dcc7b' />
									) : (
										<Hamburger color='#3dcc7b' />
									)}
								</>
							}></IconButton>
					</HStack>

					{/* / Top Nav */}
				</Flex>

				<Collapse in={menuIsOpen} animateOpacity>
					{/* {menuTopLevel && menuTopLevel.length > 0 ? (
						<DesktopMainMenu
							menuTopLevel={menuTopLevel}
							isLoggedIn={isLoggedIn}
						/>
					) : null} */}
					{menuTopLevel && menuTopLevel.length > 0 ? (
						<MobileMainMenu
							menuTopLevel={menuTopLevel}
							isLoggedIn={user?.isLoggedIn}
							activeSubscription={activeSubscription}
						/>
					) : null}
				</Collapse>
			</Box>
		</Box>
	);
}

export default MainMenu;

// eslint-disable-next-line react/prop-types
const MemberLinks = ({isLoggedIn}) => (
	<Stack direction={{base: 'column', xl: 'row'}}>
		{!isLoggedIn && (
			<>
				<LinkButton
					href={joinLink.url}
					text={joinLink.title}
					isExternal={joinLink.target === '_blank'}
					variant='primary'
					justifyContent='center'
					mb={{base: 2, xl: 0}}
				/>
				<LinkButton
					href={logInLink.url}
					text={logInLink.title}
					isExternal={logInLink.target === '_blank'}
					variant='outline'
					justifyContent='center'
					mb={{base: 2, xl: 0}}
				/>
			</>
		)}
	</Stack>
);

// eslint-disable-next-line react/prop-types
const AccordionMenu = ({linkItem}) => {
	/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment */
	const {title, links} = linkItem;
	return (
		<Menu closeOnSelect size={'sm'}>
			{({isOpen}) => (
				<>
					{title === 'Profile' ? (
						<MenuButton
							className='mobile-menu-profile'
							isActive={isOpen}
							as={Button}
							rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
							leftIcon={<AccountAvatar />}
							transition='all 0.2s'
							bg='none'
							color='clach.900'
							_active={{bg: 'none'}}
							_hover={{bg: 'none', color: 'flag.500'}}
							_expanded={{bg: 'none'}}
							_focus={{boxShadow: 'outline'}}
							fontSize={16}
							pr={2}></MenuButton>
					) : (
						<MenuButton
							isActive={isOpen}
							as={Button}
							transition='all 0.2s'
							bg='none'
							color='clach.900'
							_active={{bg: 'none'}}
							_hover={{bg: 'none', color: 'flag.500'}}
							_expanded={{bg: 'none'}}
							_focus={{boxShadow: 'outline'}}
							rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
							fontSize={16}
							pr={2}>
							{title}
						</MenuButton>
					)}
					<MenuList w='1' borderRadius='none' boxShadow='md'>
						{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
						{links.map((link, i) => (
							<MenuItem
								minH='48px'
								key={`section-on-tmenu-item${link.title}_${i}`}
								as='a'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								href={link.url}
								_hover={{
									bg: 'none',
									color: 'flag.500',
									textDecoration: 'underline',
								}}>
								{link.title}
							</MenuItem>
						))}
						{title === 'Profile' && (
							<form action={logOutApiButton.url} method='POST'>
								<MenuItem
									minH='48px'
									as='button'
									_hover={{
										bg: 'none',
										color: 'flag.500',
										textDecoration: 'underline',
									}}
									type='submit'>
									{logOutApiButton.title}
								</MenuItem>
							</form>
						)}
					</MenuList>
				</>
			)}
		</Menu>
	);
};

const MenuTopLevel = ({
	// eslint-disable-next-line react/prop-types
	menuTopLevel,
	// eslint-disable-next-line react/prop-types
	isLoggedIn,
	// eslint-disable-next-line react/prop-types
	activeSubscription,
}) => (
	<Stack
		direction={{base: 'row', xl: 'row'}}
		alignItems='center'
		display={{base: 'none', xl: 'flex'}}
		mr={4}>
		{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
		{menuTopLevel.map((linkItem, i) =>
			canViewItem(linkItem, isLoggedIn, activeSubscription) ? (
				<Box key={`section-on-top-link${linkItem.title}_${i}`}>
					{linkItem.links.length > 1 ? (
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						<AccordionMenu linkItem={linkItem} />
					) : (
						<Box
							key={`section-on-top-link${linkItem.title}_${i}`}
							as='a'
							_focus={{boxShadow: 'outline'}}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							href={linkItem.links[0].url ? linkItem.links[0].url : '#'}
							textStyle={{
								base: 'paragraph2LeftDark',
							}}
							_hover={{
								color: 'flag.500',
							}}
							px={3}
							py={2}>
							{linkItem.title}
						</Box>
					)}
				</Box>
			) : null,
		)}
	</Stack>
);

const canViewItem = (linkItem, isLoggedIn, activeSubscription?) => {
	let canView = true;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {title} = linkItem;

	// member-restricted content:
	if (linkItem.membersOnly && linkItem.membersOnly === true) {
		// disable if we don't have a logged-in user:
		if (!isLoggedIn) {
			canView = false;
		}

		if (activeSubscription?.length === 0 && title !== 'Profile') {
			canView = false;
		}
	}

	return canView;
};

// eslint-disable-next-line react/prop-types
const MobileMainMenu = ({menuTopLevel, isLoggedIn, activeSubscription}) => (
	<Flex display={{base: 'flex', xl: 'none'}} direction='column' py={4}>
		<Accordion allowMultiple pt={4}>
			{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
			{menuTopLevel.map((menu, i) => {
				if (
					menu.links.length > 1
					&& canViewItem(menu, isLoggedIn, activeSubscription)
				) {
					return (
						<AccordionItem border='none' key={`mobile-menu-item-${i}`}>
							{({isExpanded}) => (
								<>
									<AccordionButton px='0' style={{backgroundColor: '#fff'}}>
										<Box
											flex='1'
											textAlign='left'
											textStyle={{
												base: 'heading6LeftLeaf',
												md: 'heading6LeftLeaf',
											}}>
											{menu?.title}
										</Box>
										{isExpanded ? (
											<ChevronUp24 w={5} h={5} color='#3dcc7b' />
										) : (
											<ChevronDown24 w={5} h={5} color='#3dcc7b' />
										)}
									</AccordionButton>

									<AccordionPanel p={'15px 0 25px 0'}>
										<Stack direction='column' spacing='24px' ml='0' pl='0'>
											{/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
											{menu.links.map((linkItem, i) => (
												<Box
													key={`${linkItem.title}_${i}`}
													as='a'
													// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
													href={linkItem.url}
													textStyle={{
														base: 'paragraph1LeftDark',
													}}
													_hover={{
														color: 'flag.500',
													}}>
													{linkItem.title}
												</Box>
											))}
										</Stack>
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					);
				}

				if (canViewItem(menu, isLoggedIn, activeSubscription)) {
					return (
						<Stack
							direction='column'
							ml='0'
							pl='0'
							key={`${menu.title}_${i}_RHSStack`}>
							<Box
								textStyle={{
									base: 'heading6LeftLeaf',
									md: 'heading6LeftLeaf',
								}}
								key={`${menu.title}_${i}_RHSButton`}
								as='a'
								_hover={{
									color: 'flag.500',
								}}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								href={menu.links[0].url ? menu.links[0].url : '#'}
								py={'8px'}>
								{menu.title}
							</Box>
						</Stack>
					);
				}

				return null;
			})}
			{isLoggedIn && (
				<form action={logOutApiButton.url} method='POST'>
					<Box
						textStyle={{
							base: 'heading6LeftLeaf',
							md: 'heading6LeftLeaf',
						}}
						as='button'
						type='submit'
						key={'logout'}
						_hover={{
							color: 'flag.500',
						}}
						py={'8px'}>
						Logout
					</Box>
				</form>
			)}
		</Accordion>

		<Box display={{base: 'row', xl: 'none'}} pt={3}>
			{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
			<MemberLinks isLoggedIn={isLoggedIn} />
		</Box>
	</Flex>
);
