const AccountAvatar = () => (
	<svg width='48' height='48' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
		<g fill='none' fillRule='evenodd'>
			<circle fill='#b2dc6b' cx='24' cy='24' r='24'/>
			<g transform='translate(12 12)'>
				<rect fill='none' width='24' height='24' rx='4'/>
				<g transform='translate(4 3)' stroke='#00312D' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
					<path d='M16 18v-2a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2'/>
					<circle cx='8' cy='4' r='4'/>
				</g>
			</g>
		</g>
	</svg>
);

export default AccountAvatar;
