/* eslint @typescript-eslint/naming-convention: 1, react/prop-types: 1 */
import React from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention, react/prop-types
const LogoASSC = ({width, height}) => (
	<svg width='137' height='44' viewBox='0 0 137 44' xmlns='http://www.w3.org/2000/svg' >
		<defs>
			<path id='5hoqzf17ca' d='M0 0h16.648v19.034H0z'/>
			<path id='q5ykzszqxc' d='M0 44h137V0H0z'/>
		</defs>
		<g fill='none' fillRule='evenodd'>
			<path d='m84.16 22.062-2.238-6.388c-.26-.756-.31-1.408-.31-1.408h-.08s-.076.652-.31 1.408l-2.264 6.388h5.202zm-11.68 6.177h1.77l5.852-16.373h2.94l5.852 16.373h1.77v2.036h-6.373V28.24h1.95l-1.43-4.09h-6.504l-1.43 4.09h1.978v2.036h-6.376V28.24zM94.074 24.748v1.355c0 1.356 1.743 2.164 3.669 2.164 2.105 0 3.641-.99 3.641-2.814 0-2.14-2.16-2.842-4.423-3.729-2.418-.912-4.889-1.957-4.889-5.214 0-3.47 2.784-4.957 5.878-4.957 2.756 0 5.567 1.045 5.567 3.052v2.165h-2.419v-1.28c0-1.066-1.64-1.639-3.148-1.639-1.795 0-3.225.833-3.225 2.526 0 1.957 1.847 2.61 3.9 3.39 2.707 1.018 5.387 2.059 5.387 5.476 0 3.519-2.862 5.345-6.27 5.345-2.862 0-6.06-1.278-6.06-3.912v-1.928h2.392zM108.461 24.748v1.355c0 1.356 1.743 2.164 3.669 2.164 2.105 0 3.641-.99 3.641-2.814 0-2.14-2.16-2.842-4.423-3.729-2.418-.912-4.889-1.957-4.889-5.214 0-3.47 2.784-4.957 5.878-4.957 2.756 0 5.567 1.045 5.567 3.052v2.165h-2.419v-1.28c0-1.066-1.64-1.639-3.148-1.639-1.795 0-3.225.833-3.225 2.526 0 1.957 1.847 2.61 3.9 3.39 2.707 1.018 5.387 2.059 5.387 5.476 0 3.519-2.862 5.345-6.27 5.345-2.862 0-6.06-1.278-6.06-3.912v-1.928h2.392z' fill='#006938'/>
			<g transform='translate(120.352 11.554)'>
				<path d='M9.39 0c2.212 0 6.997.808 6.997 3.65v2.243H13.97V4.46c0-1.616-2.784-2.216-4.475-2.216-3.927 0-6.814 2.89-6.814 7.064 0 4.354 2.966 7.458 6.972 7.458 1.3 0 4.576-.47 4.576-2.165v-1.433h2.42v2.241c0 2.711-4.605 3.625-7.154 3.625C3.981 19.034 0 14.784 0 9.412 0 4.015 4.085 0 9.39 0' fill='#006938' mask='url(#3dov0zse0b)'/>
			</g>
			<path fill='#006938' mask='url(#sjqjjz6lid)' d='M61.746 41.752h.723V.102h-.723z'/>
			<path fill='#8DC63F' mask='url(#sjqjjz6lid)' d='m49.347 32.607-4.677-4.813v-4.516h-2.905v1.652l-1.923-1.903v3.865h-9.943l1.212 1.243h.025V39.53h-8.447V44h26.683V32.607z'/>
			<path fill='#00A962' mask='url(#sjqjjz6lid)' d='M23.531 20.464v-1.655h2.905v4.517l2.723 2.803 6.855-6.891 3.828 3.79v-4.35h-.018l-3.369-3.469v-3.26h-2.099v1.193l-4.143-4.106-6.69 6.724v4.695z'/>
			<path fill='#006838' mask='url(#sjqjjz6lid)' d='M22.689 32.607h.022l5.689-5.715h-7.796v-5.065h-9.848l-6.279 6.308h-.024V39.53h18.236v-5.514z'/>
			<path fill='#4E6F33' mask='url(#sjqjjz6lid)' d='M28.4 26.891H29.9l-.743-.763z'/>
			<path fill='#39B24A' mask='url(#sjqjjz6lid)' d='m21.168 18.125 2.353-2.365V11.783h-.02l-4.123-4.24v-3.98h-2.563v1.453L11.748 0 .018 11.783H0v10.044h10.755l7.024-7.058z'/>
			<path fill='#308350' mask='url(#sjqjjz6lid)' d='m23.522 20.455-2.353-2.33-.55.553h-.015V21.825h2.918z'/>
			<path fill='#4C9844' mask='url(#sjqjjz6lid)' d='m39.834 23.027-3.829-3.79-6.855 6.891.741.763h9.943z'/>
			<path fill='#009549' mask='url(#sjqjjz6lid)' d='M20.603 18.678h.016l.55-.553-3.39-3.356-7.024 7.058h9.848v-2.135z'/>
			<path fill='#348B47' mask='url(#sjqjjz6lid)' d='M22.711 32.607h-.023V39.529h8.448V28.135h-.025l-1.212-1.243h-1.5z'/>
			<path fill='#009549' mask='url(#sjqjjz6lid)' d='m23.522 15.76-2.353 2.365 2.353 2.33z'/>
			<path fill='#005F30' mask='url(#sjqjjz6lid)' d='M26.435 23.325v-4.517H23.53v1.655l-.007-.007v1.37h-2.92v5.065h7.796l.759-.763z'/>
		</g>
	</svg>
);

export default LogoASSC;
