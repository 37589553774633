import React from 'react';
import {Icon, IconProps} from '@chakra-ui/react';

const ChevronUp24 = (props: IconProps) => (
	<Icon viewBox='0 0 24 24' {...props}>
		<g fill='none' fillRule='evenodd'>
			<rect fill='none' width='24' height='24' rx='4' />
			<path
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='m5 15.5 7-7 7 7'
			/>
		</g>
	</Icon>
);

export default ChevronUp24;
