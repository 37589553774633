/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/** @format */

import {useEffect, useState} from 'react';
import Router from 'next/router';
import useSWR from 'swr';
import {User} from '../src/pages/api/user';
import axios from 'axios';
import Stripe from 'stripe';

export type UseUser = {
	user: User & {
		stripe?: {
			stripeUser?: Stripe.Customer;
			stripeSubscription?: Stripe.Subscription;
		};
	};
	mutateUser: (data?: User, shouldRevalidate?: boolean) => Promise<User>;
};

export default function useUser({
	redirectTo = '',
	redirectIfFound = false,
} = {}): UseUser {
	const {data: dbUser, mutate: mutateUser} = useSWR<User>('/api/user');

	const {data: stripeUser, error: stripeUserError} = useSWR(
		() => dbUser?.id ? '/api/member/getStripeMember' : null,
		async url => axios.post(url, {
		  query: `metadata['cmsUserId']:'${dbUser.id}'`,
		}).then(res => res.data[0]),
	  );

	  const {data: stripeSubscription, error: stripeSubscriptionError} = useSWR(
		() => stripeUser ? '/api/stripe/findActiveSubscription' : null,
		async url => axios.post(url, {
		  cmsUserId: dbUser.id,
		}).then(res => res.data.activeSubscriptions[0]),
	  );

	useEffect(() => {
		// if no redirect needed, just return (example: already on /dashboard)
		// if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
		if (!redirectTo || !dbUser) {
			return;
		}

		if (
			// if redirectTo is set, redirect if the user was not found.
			(redirectTo && !redirectIfFound && !dbUser?.isLoggedIn)
			// if redirectIfFound is also set, redirect if the user was found
			|| (redirectIfFound && dbUser?.isLoggedIn)
		) {
			void Router.push(redirectTo);
		}
	}, [dbUser, redirectIfFound, redirectTo]);

	// useEffect(() => {
	// 	if (!dbUser?.id) {
	// 		return;
	// 	}

	// 	const fetchStripeUser = async () => {
	// 		// const userId = dbUser.id.slice(0, dbUser.id.length - 2);
	// 		if (!stripeUser && !stripeUserLoading) {
	// 			setStripeUserLoading(true);
	// 			try {
	// 				const res = await axios.post('/api/member/getStripeMember', {
	// 					query: `metadata['cmsUserId']:'${dbUser.id}'`,
	// 				});
	// 				setStripeUser(res.data[0]);
	// 				setStripeUserLoading(false);
	// 			} catch (error: unknown) {
	// 				setStripeUserLoading(false);
	// 				console.error(error);
	// 			}
	// 		}
	// 	};

	// 	const fetchStripeSubscription = async () => {
	// 		if (!stripeSubscription && stripeUser && !stripeSubscriptionLoading) {
	// 			setStripeSubscriptionLoading(true);
	// 			try {
	// 				const res = await axios.post('/api/stripe/findActiveSubscription', {
	// 					cmsUserId: dbUser.id,
	// 				});
	// 				setStripeSubscription(res.data.activeSubscriptions[0]);
	// 				setStripeSubscriptionLoading(false);
	// 			} catch (error: unknown) {
	// 				setStripeSubscriptionLoading(false);
	// 				console.error(error);
	// 			}
	// 		}
	// 	};

	// 	void fetchStripeUser();
	// 	void fetchStripeSubscription();
	// }, [dbUser?.id]);

	if (stripeSubscription && stripeUser) {
		return {user: {
			...dbUser,
			stripe: {
				stripeUser,
				stripeSubscription,
			},
		},
		mutateUser,
		};
	}

	if (stripeSubscription && !stripeUser) {
		return {user: {
			...dbUser,
			stripe: {
				stripeSubscription,
			},
		}, mutateUser};
	}

	if (stripeUser) {
		return {user: {
			...dbUser,
			stripe: {
				stripeUser,
			},
		}, mutateUser};
	}

	return {user: {
		...dbUser,
	}, mutateUser};
}
