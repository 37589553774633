/* eslint react/prop-types: 1, @typescript-eslint/no-unsafe-assignment: 1 */
import React from 'react';

// eslint-disable-next-line react/prop-types
const Hamburger = ({color}) => (
	<svg width='24' height='18' viewBox='0 0 24 18' xmlns='http://www.w3.org/2000/svg'>
		{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
		<g transform='translate(0 .833)' fill={color ? color : '#0A71C6'} fillRule='evenodd'>
			<rect width='24' height='2.875' rx='1.438'/>
			<rect y='6.708' width='24' height='2.875' rx='1.438'/>
			<rect y='13.417' width='24' height='2.875' rx='1.438'/>
		</g>
	</svg>
);

export default Hamburger;
