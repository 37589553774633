/**
 * /* eslint-disable react/display-name
 *
 * @format
 */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/**
 * /* eslint react/display-name: 1, @typescript-eslint/no-base-to-string: 1, @typescript-eslint/restrict-template-expressions: 1
 *
 * @format
 */

import React from 'react';
import {Link, Text, chakra, Box} from '@chakra-ui/react';
import {LinkButtonProps} from './LinkButtonProps';

// eslint-disable-next-line react/display-name
const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
	({onClick, href, text, rightIcon, leftIcon, color, ...rest}, ref) => {
		let colorRes = color || '';
		if (typeof color !== 'string') {
			colorRes = '';
		}

		return (
			<Link href={href} onClick={onClick} ref={ref} {...rest}>
				{leftIcon && (
					<chakra.span
						display='inline-flex'
						alignSelf='center'
						flexShrink={0}
						marginRight='0.5rem'>
						{leftIcon}
					</chakra.span>
				)}
				{/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
				<Box textStyle={`buttonCentre${colorRes}`}>
					{text}
				</Box>
				{rightIcon && (
					<chakra.span
						display='inline-flex'
						alignSelf='center'
						flexShrink={0}
						marginLeft='0.5rem'
					>
						{rightIcon}
					</chakra.span>
				)}
			</Link>
		);
	},
);

export default LinkButton;
