/** @format */

import React, {ReactNode} from 'react';
import Head from 'next/head';
import Footer from '../patterns/Footer';
import FooterProps from '../patterns/Footer/FooterProps';
import MainMenu from '../patterns/MainMenu';
import MainMenuProps from '../patterns/MainMenu/MainMenuProps';
import {Box} from '@chakra-ui/react';

export type LayoutProps = {
	children?: ReactNode;
	title?: string;
	seo: any;
	mainMenu?: MainMenuProps;
	footer: FooterProps;
};

const Layout = ({
	children,
	title = 'ASSC - Supporting Self-Catering in Scotland',
	seo,
	footer,
	mainMenu,
}: LayoutProps) => (
	<div>
		<Head>
			<title>{seo?.title || title}</title>
			<meta charSet='utf-8' />
			<meta name='viewport' content='initial-scale=1.0, width=device-width' />
			{seo?.description ? (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				<meta name='description' content={seo.description}></meta>
			) : null}
			{seo?.keywords ? (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				<meta name='keywords' content={seo.keywords}></meta>
			) : null}
		</Head>

		{mainMenu ? <MainMenu {...mainMenu} /> : null}
		<Box position='relative' zIndex='1'>
			{children}
		</Box>

		{footer ? <Footer {...footer} /> : null}
	</div>
);

export default Layout;
