/**
 * eslint @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-require-imports: 1, @typescript-eslint/no-var-requires: 1, @typescript-eslint/naming-convention: 1, @typescript-eslint/no-unsafe-call: 1, react/prop-types: 1, @typescript-eslint/no-throw-literal: 1, @typescript-eslint/prefer-optional-chain: 1, @typescript-eslint/no-implicit-any-catch: 1
 *
 * @format
 */

/** @format */

import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Stack,
	Text,
	useBreakpointValue,
	useMediaQuery,
} from '@chakra-ui/react';
import {Field, Form, Formik} from 'formik';
import Image from 'next/image';
import {useCallback, useRef, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import SpacerComponent from '../../components/SpacerComponent';
import {ModalNewsLetterProps} from './ModalNewsLetterProps';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const mailchimp = require('@mailchimp/mailchimp_marketing');

// eslint-disable-next-line @typescript-eslint/naming-convention
const API_KEY = process.env.NEXT_PUBLIC_MAILCHIMP_SECRET;
// eslint-disable-next-line @typescript-eslint/naming-convention
const DATACENTER = API_KEY.split('-')[1];

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
mailchimp.setConfig({
	apiKey: API_KEY,
	server: DATACENTER,
});

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Too short!')
		.max(20, 'Too long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	agree: Yup.boolean()
		.required('Please read and accept the terms and conditions.')
		.oneOf([true], 'Please read and accept the terms and conditions.'),
});

const ModalNewsLetter = ({isOpen, onOpen, onClose}: ModalNewsLetterProps) => {
	const size = useBreakpointValue({
		base: 'full',
		lg: '6xl',
	}, {ssr: true});

	const initialRef = useRef();
	const finalRef = useRef();
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [hasSuccess, setHasSuccess] = useState(false);
	const [loadingMailChimp, setLoadingMailChimp] = useState(false);
	const [lg] = useMediaQuery('(min-width: 992px)');
	const {executeRecaptcha} = useGoogleReCaptcha();
	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('ModalNewsLetter');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	const onSubmit = async (
		values: {
			email: string;
			name: string;
		},
		gReCaptchaToken: string,
		resetForm: () => void,
	) => {
		setLoadingMailChimp(true);

		try {
			const subscribe = async () => {
				const response = await fetch('/api/subscribe', {
					body: JSON.stringify({
						email: values.email,
						name: values.name,
						gReCaptchaToken,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				});

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const {error} = await response.json();
				if (error) {
					// eslint-disable-next-line @typescript-eslint/no-throw-literal
					throw error;
				}

				return response;
			};

			const response = await subscribe();

			if (response?.ok) {
				setLoadingMailChimp(false);

				setHasSuccess(true);
				resetForm();
			}
		} catch (error: unknown) {
			setHasError(true);

			setLoadingMailChimp(false);
			if (error === 'Member Exists') {
				setErrorMessage(
					'You have already subscribed to this newsletter. Please check your inbox to find our newsletters.',
				);
			}

			console.error('mailchimp api fail', error);
		}
	};

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onClose={onClose}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			isOpen={isOpen}
			size={size}
			isCentered={lg}
			motionPreset='slideInBottom'
			// blockScrollOnMount={true}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<HStack justifyContent={'space-between'}>
						<Image
							src={'/icons/component-logo-assc.png'}
							width='128'
							height='41'
						/>
						<Box
							onClick={() => {
								setHasError(false);
								setHasSuccess(false);
								// eslint-disable-next-line @typescript-eslint/no-unsafe-call
								onClose();
							}}>
							<Image
								src={'/icons/icon-close-green-24-pt.svg'}
								width='22'
								height='22'
							/>
						</Box>
					</HStack>
				</ModalHeader>

				<Box borderBottom='2px' borderColor='gneiss.200' />

				{!hasError && !hasSuccess ? (
					<DefaultModalBody
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						onClose={onClose}
						initialRef={initialRef}
						setHasSuccess={setHasSuccess}
						setHasError={setHasError}
						loadingMailChimp={loadingMailChimp}
						setLoadingMailChimp={setLoadingMailChimp}
						setErrorMessage={setErrorMessage}
						onSubmit={onSubmit}
						addRecaptcha={addRecaptcha}
					/>
				) : null}
				{hasError ? (
					<ErrorModalBody
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						onClose={onClose}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						onOpen={onOpen}
						setHasError={setHasError}
						errorMessage={errorMessage}
					/>
				) : null}
				{hasSuccess ? (
					<SuccessfulModalBody
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						onClose={onClose}
						setHasError={setHasError}
						setHasSuccess={setHasSuccess}
					/>
				) : null}
				{/* <ModalFooter>
			<ModalFooter>
			  <Button variant="ghost">Secondary Action</Button>
			</ModalFooter>
		  </ModalFooter> */}
			</ModalContent>
		</Modal>
	);
};

export default ModalNewsLetter;

const DefaultModalBody = ({
	// eslint-disable-next-line react/prop-types
	onClose,
	// eslint-disable-next-line react/prop-types
	initialRef,
	// eslint-disable-next-line react/prop-types
	setHasSuccess,
	// eslint-disable-next-line react/prop-types
	setHasError,
	// eslint-disable-next-line react/prop-types
	loadingMailChimp,
	// eslint-disable-next-line react/prop-types
	setLoadingMailChimp,
	// eslint-disable-next-line react/prop-types
	setErrorMessage,
	// eslint-disable-next-line react/prop-types
	onSubmit,
	// eslint-disable-next-line react/prop-types
	addRecaptcha,
}) => (
	<ModalBody pb='53px'>
		<Text
			pt='34px'
			textStyle={{
				base: 'display6LeftDark',
			}}
			whiteSpace='pre-line'
			w='100%'>
			Stay ahead in self-catering
		</Text>

		<Text
			py={{base: '16px', lg: '20px'}}
			textStyle={{
				base: 'paragraph1LeftDark2',
			}}
			whiteSpace='pre-line'
			w='100%'>
			Sign up to the ASSC newsletter to hear about industry news and updates on
			ways to enhance your business.
		</Text>

		<Formik
			initialValues={{
				name: '',
				email: '',
				agree: false,
			}}
			validationSchema={ContactSchema}
			onSubmit={async (values, {resetForm}) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				const gReCaptchaToken = await addRecaptcha(values);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				await onSubmit(values, gReCaptchaToken, resetForm);
			}}>
			{({errors, touched}) => (
				<Form>
					<Stack direction={{base: 'column', lg: 'row'}} spacing='16px'>
						<Box flex={{base: '1', lg: '1'}} pt='32px'>
							<Field name='name'>
								{({field, form}) => (
									<FormControl isInvalid={Boolean(touched.name && errors.name)}>
										<label>Name</label>
										<Input
											name='name'
											type='text'
											{...field}
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
											ref={initialRef}
											placeholder='Name'
										/>
										<FormErrorMessage>{form.errors.name}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</Box>
						<Box flex={{base: '1', lg: '1'}} pt='32px'>
							<Field name='email'>
								{({field, form}) => (
									<FormControl
										isInvalid={Boolean(touched.email && errors.email)}>
										<label>Email address</label>
										<Input
											name='email'
											type='text'
											{...field}
											placeholder='Email address'
										/>
										<FormErrorMessage>{form.errors.email}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</Box>
					</Stack>

					<Stack
						direction={{base: 'column', lg: 'row'}}
						spacing='16px'
						pt={{base: '16px', lg: '33px'}}>
						<SpacerComponent />
						<Box flex={{base: '1', lg: '1'}}>
							<Field name='agree'>
								{({field, form}) => (
									<FormControl
										isInvalid={Boolean(touched.agree && errors.agree)}>
										<Checkbox
											size='md'
											colorScheme='lime'
											{...field}
											name='agree'>
											<Text
												textStyle={{
													base: 'paragraph2LeftItalicDark2',
												}}>
												I agree that I have read the{' '}
												<Link href='/data-privacy-notice' variant='native'>
													privacy policy
												</Link>{' '}
												and consent to ASSC contacting me.
											</Text>
										</Checkbox>
										<FormErrorMessage>{form.errors.agree}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</Box>
						<Box>
							<Button
								size='lg'
								mt='40px'
								// disabled={
								//   (errors.agree || errors.name || errors.email) &&
								//   (touched.agree || touched.name || touched.email)
								// }
								type='submit'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								isLoading={loadingMailChimp}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								isDisabled={loadingMailChimp}
								spinnerPlacement='end'
								loadingText={'Signing Up'}
								colorScheme='blue'
								w={{base: '100%', lg: '11.3rem'}}>
								Sign Up{' '}
							</Button>
						</Box>
					</Stack>
				</Form>
			)}
		</Formik>
	</ModalBody>
);

// eslint-disable-next-line react/prop-types
const ErrorModalBody = ({onClose, onOpen, setHasError, errorMessage}) => (
	<ModalBody pb='53px'>
		<Text
			pt='34px'
			textStyle={{
				base: 'display6LeftDark',
			}}
			whiteSpace='pre-line'
			w='100%'>
			It looks like something went wrong…
		</Text>

		{errorMessage ? (
			<Text
				py={{base: '16px', lg: '20px'}}
				textStyle={{
					base: 'paragraph1LeftDark2',
				}}
				whiteSpace='pre-line'
				w='100%'>
				{errorMessage}
			</Text>
		) : (
			<Text
				py={{base: '16px', lg: '20px'}}
				textStyle={{
					base: 'paragraph1LeftDark2',
				}}
				whiteSpace='pre-line'
				w='100%'>
				Sorry! It looks like something went wrong, please try again later.
			</Text>
		)}

		<Stack
			direction={{base: 'column', lg: 'row'}}
			spacing='16px'
			pt={{base: '16px', lg: '33px'}}>
			<Box justifyContent={'flex-end'}>
				<Flex mt='40px' flexDirection={{base: 'column', lg: 'row'}}>
					<Button
						colorScheme='blue'
						onClick={() => {
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							setHasError(false);
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							onOpen();
						}}
						w={{base: '100%', lg: '11.3rem'}}>
						Try Again
					</Button>
					<Spacer mr={{base: 0, lg: '16px'}} mt={{base: '16px', lg: 0}} />
					<Button
						variant='secondary'
						colorScheme='lime'
						onClick={() => {
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							setHasError(false);
							// eslint-disable-next-line @typescript-eslint/no-unsafe-call
							onClose();
						}}
						w={{base: '100%', lg: '11.3rem'}}>
						Done
					</Button>
				</Flex>
			</Box>
		</Stack>
	</ModalBody>
);

// eslint-disable-next-line react/prop-types
const SuccessfulModalBody = ({onClose, setHasError, setHasSuccess}) => (
	<ModalBody pb='53px'>
		<Text
			pt='34px'
			textStyle={{
				base: 'display6LeftDark',
			}}
			whiteSpace='pre-line'
			w='100%'>
			Thanks for Subscribing!
		</Text>

		<Text
			py={{base: '16px', lg: '20px'}}
			textStyle={{
				base: 'paragraph1LeftDark2',
			}}
			whiteSpace='pre-line'
			w='100%'>
			We will be in touch soon with an update from the ASSC team.
		</Text>

		<Stack
			direction={{base: 'column', lg: 'row'}}
			spacing='16px'
			pt={{base: '16px', lg: '33px'}}>
			<Box justifyContent={'flex-end'}>
				<Button
					mt='40px'
					colorScheme='lime'
					onClick={() => {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						setHasError(false);
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						setHasSuccess(false);
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						onClose();
					}}
					w={{base: '100%', lg: '11.3rem'}}>
					Done
				</Button>
			</Box>
		</Stack>
	</ModalBody>
);
