/* eslint react/prop-types: 1, @typescript-eslint/no-unsafe-assignment: 1 */
import React from 'react';

// eslint-disable-next-line react/prop-types
const Close = ({color}) => (
	<svg width='34' height='36' viewBox='0 0 32 36' xmlns='http://www.w3.org/2000/svg'>
		{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
		<g strokeWidth='2' stroke={color ? color : '#0A71C6'} fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
			<path d='M11.39 28.921 29.53 11M11.079 10.89 29 29.03'/>
		</g>
	</svg>
);

export default Close;
