/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1, react/prop-types: 1, eqeqeq: 1, @typescript-eslint/prefer-optional-chain: 1
 *
 * @format
 */

import {
	Box,
	Button,
	Container,
	Flex,
	HStack,
	Stack,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import FooterProps, {ContactDetails} from '../Footer/FooterProps';
import ModalNewsLetter from '../ModalNewsLetter';

function Footer({
	copyright,
	privacyPolicy,
	termsAndConditions,
	siteMap,
	newsletterSignup,
	socialMedias,
	contactDetails,
}: FooterProps) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {title, description, cta} = newsletterSignup;

	return (
		<VStack
			as='footer'
			bg={'aquaGreen.900'}
			color='arctic'
			display={{
				lg: 'flex',
			}}>
			<Container
				maxW='container.xl'
				mx='auto'
				py={{base: 8}}
				px={{base: 4}}
				p={{lg: '80px 12px'}}>
				<Flex
					justifyContent={'space-between'}
					direction={{base: 'column', lg: 'row'}}>
					<Box
						borderRight={{base: '', lg: '1px'}}
						borderColor={'slate.600'}
						pr={{base: 0, lg: 40}}
						pb={{base: 10}}>
						<NewsletterSignup
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							title={title}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							description={description}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							cta={cta}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							newsletterSignup={newsletterSignup}
						/>
					</Box>

					<ContactUs {...contactDetails} />

					<SocialMedias socialMedias={socialMedias} />
				</Flex>
			</Container>

			<SubFooter
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				termsAndConditions={termsAndConditions}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				privacyPolicy={privacyPolicy}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				siteMap={siteMap}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				copyright={copyright}
			/>
		</VStack>
	);
}

export default Footer;

// eslint-disable-next-line react/prop-types
const SubFooter = ({termsAndConditions, privacyPolicy, siteMap, copyright}) => (
	<HStack
		w='100%'
		bg={'leaf.900'}
		py={{
			base: '22px',
			lg: '12px',
		}}>
		<Container maxW='container.xl' mx='auto'>
			<Stack
				textStyle={{
					base: 'paragraph2LeftLight',
					lg: 'paragraph2LeftLight',
				}}>
				<Flex
					justifyContent={'space-between'}
					direction={{base: 'column', lg: 'row'}}>
					<Stack direction={{base: 'row'}}>
						{termsAndConditions ? (
							<Text
								as='a'
								target='_blank'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
								href={termsAndConditions.url}
								_hover={{
									color: colors.aquaGreen[400],
								}}>
								{/* eslint-disable-next-line react/prop-types */}
								{termsAndConditions.title}
							</Text>
						) : null}

						{privacyPolicy ? (
							<Text
								as='a'
								target='_blank'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
								href={privacyPolicy.url}
								_hover={{
									color: colors.aquaGreen[400],
								}}>
								{' | '}
								{/* eslint-disable-next-line react/prop-types */}
								{privacyPolicy.title}
							</Text>
						) : null}

						{siteMap ? (
							<Text
								as='a'
								target='_blank'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
								href={siteMap.url}
								_hover={{
									color: colors.aquaGreen[400],
								}}>
								{' | '}
								{/* eslint-disable-next-line react/prop-types */}
								{siteMap.title}
							</Text>
						) : null}
					</Stack>
					<Stack pt={{base: '16px', lg: '0'}}>
						{copyright ? (
							<Text
								as='a'
								target='_blank'
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
								href={copyright.url}
								_hover={{
									color: colors.aquaGreen[400],
								}}>
								{/* eslint-disable-next-line react/prop-types */}
								{copyright.title} {new Date().getFullYear()}
							</Text>
						) : null}
					</Stack>
				</Flex>
			</Stack>
		</Container>
	</HStack>
);

const ContactUs = (contactDetails: ContactDetails) => {
	const {header, email, phone} = contactDetails;

	return (
		<VStack alignItems={'flex-start'} minW={200} pb={8} pl={{base: 0, lg: 22}}>
			<Text as='h3' sx={textStyles.display7LeftLight} pb={2}>
				{header}
			</Text>
			<Text
				as='a'
				href={`mailto:${email}?subject=Contact from ASSC website`}
				sx={textStyles.paragraph2LeftLight}
				pb={2}>
				{email}
			</Text>
			<Text sx={textStyles.paragraph2LeftLight}>{phone}</Text>
		</VStack>
	);
};

// eslint-disable-next-line react/prop-types
const SocialMedias = ({socialMedias = []}) => {
	if (!socialMedias || socialMedias.length === 0) {
		return null;
	}

	return (
		<VStack alignItems={'flex-start'} minW={300} pl={{base: 0, lg: 22}}>
			<Text as='h3' sx={textStyles.display7LeftLight}>
				Follow us
			</Text>
			<HStack pt={{lg: '20px'}}>
				{socialMedias.map((socialMedia, i) => {
					const assetUrlBase = '/icons/component-icon-social-media-';
					const logoColour = 'green';
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					const logoName = socialMedia.title.toString().toLowerCase();
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					const logoPath = `${assetUrlBase}${logoName}--${logoColour}.svg`;

					return (
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						<Box key={`socialMedia_${i}`} as='a' href={socialMedia.url} px={2}>
							<Image
								width={40}
								height={40}
								src={logoPath}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								alt={socialMedia?.image?.alt || socialMedia?.url}
							/>
						</Box>
					);
				})}
			</HStack>
		</VStack>
	);
};

// eslint-disable-next-line react/prop-types
const NewsletterSignup = ({title, description, cta, newsletterSignup}) => {
	const {isOpen, onOpen, onClose} = useDisclosure();

	return (
		<VStack alignItems={'flex-start'} w={{base: '100%', lg: 'auto'}}>
			<Text as='h3' sx={textStyles.display7LeftLight}>
				{title}
			</Text>
			<Text
				as='p'
				pb={{base: '8px', lg: '16px'}}
				textStyle={{
					base: 'paragraph2LeftLight',
					lg: 'paragraph2LeftLight',
				}}>
				{description}
			</Text>

			{/* eslint-disable-next-line react/prop-types, @typescript-eslint/prefer-optional-chain */}
			{cta && cta.title && cta.url ? (
				<>
					<Button
						w={{base: '100%', md: 'auto'}}
						flexShrink='0'
						variant='secondary'
						onClick={() => {
							onOpen();
						}}
						justifyContent='center'>
						{/* eslint-disable-next-line react/prop-types */}
						{cta.title}
					</Button>
					<ModalNewsLetter onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
				</>
			) : null}
		</VStack>
	);
};
