/* eslint @typescript-eslint/no-unsafe-assignment: 1 */
import {Box} from '@chakra-ui/react';
import React, {ReactElement} from 'react';
import {SpacerComponentProps} from './SpacerComponentProps';

function SpacerComponent({
	size,
	backgroundColor,
}: SpacerComponentProps): ReactElement {
	let margin;
	switch (size) {
		case 'lg':
			margin = {base: '16', md: '20'};
			break;
		case 'md':
			margin = {base: '10', md: '12'};
			break;
		default:
			margin = {base: '6', md: '8'};
			break;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return <Box className='spacer' paddingTop={margin} bgColor={backgroundColor}></Box>;
}

export default SpacerComponent;
